import { FileDoneOutlined } from "@ant-design/icons";
import { Score } from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.SCORE.path,
      name: "Score",
      key: routesMap.SCORE.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      icon: <FileDoneOutlined className="text-white" />,
      element: <Score />,
    },
  ];
}
