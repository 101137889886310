import { ResumeDetails } from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: `${routesMap.Resume_Details.path}/:id`,
      name: "Resume Details",
      key: `${routesMap.Resume_Details.path}/:id`,
      commonRoute: false,
      private: true,
      belongsToSidebar: false,
      element: <ResumeDetails />,
    },
  ];
}
