import { HomeOutlined } from "@ant-design/icons";
import { AdminHome } from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.HOME.path,
      name: "Home",
      key: adminRouteMap.HOME.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      icon: <HomeOutlined className="text-white" />,
      element: <AdminHome />,
    },
  ];
}
