import { UserOutlined } from "@ant-design/icons";
import { UserManagement } from "../../../pages/Admin";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.USER_MANAGEMENT.path,
      name: "Manage Users",
      key: adminRouteMap.USER_MANAGEMENT.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      icon: <UserOutlined className="text-white" />,
      element: <UserManagement />,
    },
  ];
}
