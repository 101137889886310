import loadable from "@loadable/component";

export const Home = loadable(() => import("./Home/home"));
export const ResumeDetails = loadable(() => import("./Resume/resumeDetails"));
export const ResumeForm = loadable(() => import("./Resume/resumeForm"));
export const Login = loadable(() => import("./Auth/login"));
export const SignUp = loadable(() => import("./Auth/signUp"));
export const Score = loadable(() => import("./Score/index"));
export const InterviewQuestions = loadable(() =>
  import("./Score/InterviewQuestions/index")
);
export const AiMatchingScore = loadable(() =>
  import("./Score/AiMatchingScore/index")
);
export const BiMatricScores = loadable(() => import("./BiMatricScore/index"));
export * from "./Admin/index";
export const ChangePassword = loadable(() => import("./Auth/changePassword"));
export const UserProfile = loadable(() => import("./Profile/index"));
