import home from "./Home";
import resumeDetails from "./ResumeDetails";
import aiMatching from "./AiMatchingScore";
import biMatric from "./BiMetricScores";
import userManage from "./UserManagement";
import resetPassword from "./ResetPassword";
import profile from "./Profile";

const AccessControl = {
  ...home,
  ...resumeDetails,
  ...aiMatching,
  ...biMatric,
  ...userManage,
  ...resetPassword,
  ...profile,
};

export default AccessControl;
