import home from "./Home/index.route";
import resumeDetails from "./ResumeDetails/index.route";
import aiMatching from "./AiMatchingScore/index.route";
import biMatrics from "./BiMetricScores/index.route";
import manageUsers from "./UserManagement/index.route";
import resetPassword from "./ResetPassword/index.route";
import profile from "./Profile/index.route";

export default function route(t) {
  return [
    ...home(t),
    ...resumeDetails(t),
    ...aiMatching(t),
    ...biMatrics(t),
    ...manageUsers(t),
    ...resetPassword(t),
    ...profile(t),
  ];
}
