import loadable from "@loadable/component";

// export { default as Input } from "./Input/index.ant";
// export { default as Button } from "./Button/index.ant";
// export { default as Form } from "./Form/index.ant";
// export { default as Loader } from "./Loader/index.ant";
// export { default as Table } from "./Table/index.ant";
// export { default as Modal } from "./Modal/Modal";
// export { default as OpenNotification } from "./Alerts/index.ant";
// export { default as Password } from "./Password/index.ant";
// export { default as Card } from "./Card/index.ant";
// export { default as Footer } from "./Footer/index.ant"; N/A
// export { default as Header } from "./Header/index.ant"; N/A
// export { default as Layout } from "./Layout/index.ant";
// export { default as Sider } from "./Sider/index.ant";
// export { default as Content } from "./Content/index.ant";
// export { default as Menu } from "./Menu/index.ant";
// export { default as SearchButton } from "./Search/index.ant";
// export { default as AntBreadcrumb } from "./Breadcrumb/index.ant";
// export { default as AntDropdown } from "./Dropdown/index.ant";
// export { default as AntSelect } from "./Select/index.ant";
// export { default as AntTextArea } from "./TextArea/index.ant";
// export { default as Rate } from "./Rate/index.ant";
// export { default as Checkbox } from "./Checkbox/index.ant";
// export { default as Confirm } from "./ConfirmPopup/index.ant";
// export { default as Popovers } from "./Popover/index.ant";
// export { default as DatePicker } from "./DatePicker/index.ant";
// export { default as Switch } from "./Switch/index.ant";
// export { default as AntTooltip } from "./Tooltip/index.ant";
// export { default as Description } from "./Description/index.ant";
// export { default as Upload } from "./Upload/index.ant";
// export { default as Select } from "./Select/index.ant";
// export { default as MultiUpload } from "./MultiUpload/index.ant";
// export { default as CheckboxGroup } from "./CheckboxGroup/index.ant";
// export { default as AntCalendar } from "./Calendar/index.ant";
// export { default as RangeSlider } from "./RangeSlider/index.ant";
// export { default as Skeleton } from "./Skeleton/index.ant";

export const Input = loadable(() => import("./Input/index.ant"));
export const Button = loadable(() => import("./Button/index.ant"));
export const Form = loadable(() => import("./Form/index.ant"));
export const Loader = loadable(() => import("./Loader/index.ant"));
export const Table = loadable(() => import("./Table/index.ant"));
export const Modal = loadable(() => import("./Modal/Modal"));
export const OpenNotification = loadable(() => import("./Alerts/index.ant"));
export const Password = loadable(() => import("./Password/index.ant"));
export const Card = loadable(() => import("./Card/index.ant"));
export const Layout = loadable(() => import("./Layout/index.ant"));
export const Sider = loadable(() => import("./Sider/index.ant"));
export const Content = loadable(() => import("./Content/index.ant"));
export const Menu = loadable(() => import("./Menu/index.ant"));
export const SearchButton = loadable(() => import("./Search/index.ant"));
export const AntBreadcrumb = loadable(() => import("./Breadcrumb/index.ant"));
export const AntDropdown = loadable(() => import("./Dropdown/index.ant"));
export const AntSelect = loadable(() => import("./Select/index.ant"));
export const AntTextArea = loadable(() => import("./TextArea/index.ant"));
export const Rate = loadable(() => import("./Rate/index.ant"));
export const Checkbox = loadable(() => import("./Checkbox/index.ant"));
export const Confirm = loadable(() => import("./ConfirmPopup/index.ant"));
export const Popovers = loadable(() => import("./Popover/index.ant"));
export const DatePicker = loadable(() => import("./DatePicker/index.ant"));
export const Switch = loadable(() => import("./Switch/index.ant"));
export const AntTooltip = loadable(() => import("./Tooltip/index.ant"));
export const Description = loadable(() => import("./Description/index.ant"));
export const Upload = loadable(() => import("./Upload/index.ant"));
export const Select = loadable(() => import("./Select/index.ant"));
export const MultiUpload = loadable(() => import("./MultiUpload/index.ant"));
export const CheckboxGroup = loadable(() =>
  import("./CheckboxGroup/index.ant")
);
export const AntCalendar = loadable(() => import("./Calendar/index.ant"));
export const RangeSlider = loadable(() => import("./RangeSlider/index.ant"));
export const DocumentUploader = loadable(() => import("./Upload/index.ant"));
