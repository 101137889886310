import React, { useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import routesMap from "../../routeControl/userRouteMap";

import {
  clearSessionStorage,
  decodeQueryData,
  navigateWithParam,
} from "../../utils";
import AppLayout from "../App/index.layout";

function AuthLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname, search } = location;
  const [redirectpath, setRedirectPath] = useState("");

  // let path = pathname.replace("/", "");

  useEffect(() => {
    let pathPattern = /[\/]$/g;
    if (pathPattern.test(pathname)) {
      navigate(pathname.replace(pathPattern, ""), { replace: true });
    }
    // window.scrollTo(0, 0);
    // window.paddingTop();
    // window.addBodyClass();
    // window.removeBodyClass();
    // window.authHeight();
    if (
      // pathname !== adminRouteMap.PROVIDER_PROFILE.path &&
      pathname !== routesMap.LOGIN.path
    ) {
      clearSessionStorage();
    }
  }, [pathname]);

  // const backPath = {
  //   login: {
  //     pathName: routesMap.HOME.path,
  //     extraClassName: "",
  //   },
  //   "forgot-password": {
  //     pathName: routesMap.LOGIN.path,
  //     extraClassName: "",
  //   },
  //   "reset-password": {
  //     pathName: routesMap.LOGIN.path,
  //     extraClassName: "",
  //   },
  //   "provider-registration": {
  //     pathName: routesMap.LOGIN.path,
  //     extraClassName: "",
  //   },
  //   otp: {
  //     pathName: routesMap.FORGET_PASSWORD.path,
  //     extraClassName: "",
  //   },

  //   registration: {
  //     pathName: routesMap.LOGIN.path,
  //     extraClassName: "",
  //   },
  // };

  useEffect(() => {
    if (redirectpath) {
      if (search) {
        let newParams = decodeQueryData(search);
        navigateWithParam(newParams, navigate, redirectpath);
      } else {
        navigate(redirectpath);
      }
    }
  }, [redirectpath]);

  return (
    <>
      <AppLayout setRedirectPath={setRedirectPath}>
        <main className="main-content">
          <section className="authPage vh-100">
            <div className="row g-0 align-items-center h-100">
              <div className="col-6">
                <div className="authPage_left text-center">
                  {/* <h3 style={{ color: "#1677ff", marginTop: "2%" }}>
                    Resume Parser
                  </h3> */}
                </div>
              </div>
              <div className="col-6">
                <div className="authPage_right">
                  <Outlet />
                </div>
              </div>
            </div>
          </section>
        </main>
      </AppLayout>
    </>
  );
}

export default AuthLayout;
