import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";

function NoDataFound({
  icon = <ExclamationCircleOutlined />,
  text = "No Data Found",
  extraClass = "",
}) {
  return (
    <div className="emptySec text-center position-relative d-flex flex-column justify-content-center align-items-center">
      <div className="fileUpload">
        <label className="bg-transparent border-0 text-danger">{icon}</label>
      </div>
      <h2 className={`text-400 emptySec_text ${extraClass}`}>{text}</h2>
    </div>
  );
}

export default NoDataFound;
