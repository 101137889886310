import { ChangePassword } from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.RESET_PASSWORD.path,
      name: "Resume Details",
      key: adminRouteMap.RESET_PASSWORD.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: false,
      element: <ChangePassword />,
    },
  ];
}
