import { appLanguage as common } from "./Common/index.i18n";

export default function lang() {
  return {
    text: {
      common: common.text,
    },
    validation: { common: common.validation },
  };
}
