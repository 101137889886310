import home from "./Home";
import resumeForm from "./ResumeForm";
import resumeDetails from "./ResumeDetails";
import score from "./Score";
import aiMatchingScore from "./AiMatchingScore";
import biMatric from "./BiMetricScores";
import profile from "./Profile";

const AccessControl = {
  ...home,
  ...resumeForm,
  ...resumeDetails,
  ...score,
  ...aiMatchingScore,
  ...biMatric,
  ...profile,
};

export default AccessControl;
