import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "antd/es/layout/layout";
import { Layout, theme } from "antd";
import {
  DownOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  // LogoutOutlined,
  SettingOutlined,
  UserOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { moduleRoutesList } from "../../routes/index";
import { logout, selectUserData } from "../../redux/auth/index.slice";
import AppLayout from "../App/index.layout";
import {
  AntDropdown,
  Content,
  MenuComponent,
  Sider,
  SweetAlert,
} from "../../components";
import routesMap from "../../routeControl/userRouteMap";
import adminRouteMap from "../../routeControl/adminRouteMap";
import { textFormatter } from "../../utils";

function PrivateLayout() {
  const [collapsed, setCollapsed] = useState(false);

  const [redirectpath, setRedirectPath] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const history = useNavigate();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const getmoduleRoutesList = moduleRoutesList();

  const userData = useSelector(selectUserData);
  const Role = userData?.role === "superadmin" ? "admin" : "user";
  let user = pathname.includes("superadmin") ? "superadmin" : "user";
  let userRole = Role ?? user;
  const { t } = useTranslation();

  useEffect(() => {
    if (redirectpath) {
      navigate(redirectpath);
    }
  }, [redirectpath]);
  const dispatch = useDispatch();

  const items = [
    {
      label: "Profile",
      path:
        userData?.role === "user"
          ? routesMap.PROFILE.path
          : adminRouteMap.PROFILE.path,
      icon: <ProfileOutlined />,
    },
    {
      type: "divider",
    },

    {
      label: "Change Password",
      path:
        userData?.role === "user"
          ? routesMap.RESET_PASSWORD.path
          : adminRouteMap.RESET_PASSWORD.path,
      icon: <SettingOutlined />,
    },
    {
      type: "divider",
    },
    {
      label: "Logout",
      path: "#",
      onClick: (e) => {
        e.preventDefault();
        setIsAlertVisible(true);
      },
      icon: <LogoutOutlined />,
    },
  ];
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <AppLayout setRedirectPath={setRedirectPath}>
        <Layout>
          <Header className="header d-flex align-items-center">
            <div className="logo">
              {" "}
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger text-white text-truncate ",
                  onClick: () => setCollapsed(!collapsed),
                }
              )}{" "}
              Resume Parser
            </div>
            <AntDropdown
              sideBarData={items}
              icon={<UserOutlined className="text-truncate" />}
              icon2={<DownOutlined className="text-truncate" />}
              userName={`${textFormatter(userData?.first_name)}`}
              type="primary"
              extraClass="logo logoutBtn btn btn-primary"
              button
            />
          </Header>
        </Layout>
        <Layout>
          <Sider
            width={200}
            className="site-layout"
            collapsible
            collapsed={collapsed}
            theme="dark"
            mode="inline"
          >
            {" "}
            <MenuComponent
              className="ant-menu-item-active"
              routes={getmoduleRoutesList?.[userRole]}
            />
          </Sider>
          <Content
            style={{
              padding: "50px",
              minHeight: "100vh",
              background: colorBgContainer,
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </AppLayout>{" "}
      <SweetAlert
        reverseButtons
        icon="warning"
        confirmButtonColor="blue"
        title={t("text.common.areYouSure")}
        text={t("text.common.logout")}
        showCancelButton
        setIsAlertVisible={setIsAlertVisible}
        cancelButtonText={t("text.common.no")}
        confirmButtonText={t("text.common.yes")}
        onConfirmAlert={() => dispatch(logout(history))}
        show={isAlertVisible}
        showLoaderOnConfirm
      />
    </>
  );
}

export default PrivateLayout;
