const AccessControl = {
  LOGIN: {
    path: "/",
  },
  FORGET_PASSWORD: {
    path: "/forgot-password",
  },
  SIGNUP: {
    path: "/sign-up",
  },
  ADMIN_SIGNUP: {
    path: "/admin-sign-up",
  },
  RESET_PASSWORD: {
    path: "/reset-password",
  },
  VERIFICATION: {
    path: "/otp",
  },
  // SIGNUP_AS_PROVIDER: {
  //   path: "/provider-registration",
  // },
};

export default AccessControl;
