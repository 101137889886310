import React from "react";
import { useTranslation } from "react-i18next";
import NoDataFound from "./NoDataFound";

function NotFound() {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <div className="auth-wrapper">
        <div className="auth-inner text-center">
          <NoDataFound text={t("text.common.notFound")} />
        </div>
      </div>
      {/* <div className="auth-wrapper">
        <div className="auth-inner text-center">
          {t("text.common.notFound")}
        </div>
      </div> */}
    </>
  );
}

export default NotFound;
