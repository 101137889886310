import loadable from "@loadable/component";

export const AccordionComponent = loadable(() => import("./Accordion/index"));
export const MenuComponent = loadable(() => import("./Menu/index.menu"));
export const CommonButton = loadable(() => import("./CommonButton/index"));
export const TabComponent = loadable(() => import("./Tabs/index"));
export const GlobalLoader = loadable(() => import("./GlobalLoader/index"));
export const FileUploader = loadable(() => import("./FileUploader/index"));
export const Charts = loadable(() => import("./Chart/index"));
export const ModalComponent = loadable(() => import("./Modal/Index"));
export const DataTable = loadable(() => import("./DataTable/index"));
export const Pagination = loadable(() => import("./Pagination/index"));
export const ActionDropdown = loadable(() => import("./ActionDropdown/index"));
export const ProgressBarComponent = loadable(() =>
  import("./Progressbar/index")
);
export const CustomSwitch = loadable(() => import("./Switch/index"));
export const SweetAlert = loadable(() => import("./SweetAlert/index"));
