export const validation = {
  email: "Email is required!",
  password: "Password is required!",
  firstName: "First name is required!",
  lastName: "Last name is required!",
  role: "Role is required!",
  organization: "Organization is required!",
  firstNameValid: "First name should be in the alphabets ",
  lastNameValid: "Last name should be in the alphabets",
  mobileNumber: "Mobile number is required.",
  confirmPassword: "Confirm password is required",
  match: "Password & Confirm password must be same",
  validEmail: "Enter Valid email",
  minFirstname: "First name must be minimum 2 characters",
  minLastname: "Last name must be minimum 3 characters",
  maxFirstname: "First name must be Maximum 15 characters",
  maxLastname: "Last name must be Maximum 15 characters",
  minPhoneno: "Phone number must be at least 9 digits",
  maxPhoneNo: "Please enter a valid mobile number",
  phoneCode: "Please select dialling code",
  validPassword: "Password Must be 6 to 15 Characters",
};
