export const text = {
  lastName: "Last Name",
  firstName: "First Name",
  email: "Email",
  role: "Role",
  password: "Password",
  organization: "Organization",
  signUp: "Sign-Up",
  login: "Login",
  enterFirstName: "Enter first name",
  enterLastName: "Enter last name",
  enterEmail: "Enter email address",
  enterOrganization: "Enter organization name",
  selectRole: "Select Role",
  enterPassword: "Enter password",
  logIn: "Log in",
  jobDescriptionDetail: "Job Description Detail",
  resumeDetails: "Resume Details",
  personalInformation: "Personal Information",
  education: "Education",
  employmentHistory: "Employment History",
  skills: "Skills",
  resumeUploadTitle: "Resume Upload",
  uploadResumeText: "Click or drag file to this area to upload resume",
  resume: "Resume",
  jobDescription: "Job Description",
  jobDescriptionUploadTitle: "Job Description Upload",
  uploadJobDescriptionText:
    "Click or drag file to this area to upload job description",
  resumeList: "Resume List",
  jobDescriptionList: "Job Description List",
  view: "View",
  download: "  Download",
  action: "Action",
  uploadDate: "Upload Date",
  name: "Name",
  contact: "Contact",
  experience: "Experience",
  notFound: "404 - Not Found",
  generateQuestion: "Generate Interview Questions",
  generateEmployment: "Generate Employment History",
  operationType: "Operation Type",
  skill: "Skills",
  title: "Title",
  aiMatchingScore: "Ai Matching Score",
  allBiMetricScore: "All Bi Metric Score",
  submit: "Submit",
  clickHere: "Click or Drag file to this area",
  degrees: "Degrees",
  baseCandidate: "Base Candidate",
  candidate: "Base Candidate",
  address: "Address",
  region: "Region",
  city: "City",
  cancel: "Cancel",
  base_candidate_name: "Base Candidate Name",
  candidateName: "Candidate Name",
  viewScore: "View Score",
  back: "Back",
  biMetricScore: "Bi Metric Score",
  job_description: "Job Description",
  documentList: "Document List",
  manageUsers: "Manage Users",
  status: "Status",
  deleteUser: "To Delete Document",
  areYouSure: "Are You Sure?",
  confirmText: "Confirm",
  deleteDoc: "Delete Document",
  confirmPassword: "Confirm Password",
  enterConfirmPassword: "Enter confirm password",
  oldPassword: "Old Password",
  enterOldPassword: "Enter old password",
  newPassword: "New Password",
  changePassword: "Change Password",
  logout: "You want to logout",
  yes: "Yes",
  no: "No",
  generateProjectDetails: "Generate Project Details",
  generatePersonalInfo: "Generate Personal Information",
};
