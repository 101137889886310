import home from "./Home/index.route";
import resumeUpload from "./Uploader/index.route";
import resumeDetails from "./ResumeDetails/index.route";
import score from "./Score/index.route";
import aiMatchingScore from "./AiMatchingScore/index.route";
import biMatrics from "./BiMetricScores/index.route";
import resetPassword from "./ResetPassword/index.route";
import profile from "./Profile/index.route";

export default function route(t) {
  return [
    ...home(t),
    ...resumeUpload(t),
    ...resumeDetails(t),
    ...score(t),
    ...aiMatchingScore(t),
    ...biMatrics(t),
    ...resetPassword(t),
    ...profile(t),
  ];
}
