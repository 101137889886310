import { Login, SignUp } from "../pages";
import routesMap from "../routeControl/userRouteMap";

export default function route(t) {
  return [
    {
      path: routesMap.LOGIN.path,
      name: t("text.common.login"),
      key: routesMap.LOGIN.path,
      commonRoute: false,
      private: false,
      belongsToHeader: false,
      element: <Login />,
    },
    // {
    //   path: routesMap.FORGET_PASSWORD.path,
    //   name: t("text.forgotPassword.title"),
    //   key: routesMap.FORGET_PASSWORD.path,
    //   commonRoute: false,
    //   private: false,
    //   belongsToHeader: false,
    //   element: <ForgetPassword />,
    // },
    {
      path: routesMap.SIGNUP.path,
      name: "Sign-Up",
      key: routesMap.SIGNUP.path,
      commonRoute: false,
      private: false,
      belongsToHeader: false,
      element: <SignUp />,
    },
    // {
    //   path: routesMap.RESET_PASSWORD.path,
    //   name: t("text.forgotPassword.title"),
    //   key: routesMap.RESET_PASSWORD.path,
    //   commonRoute: false,
    //   private: false,
    //   belongsToHeader: false,
    //   element: <ResetPassword />,
    // },
    {
      path: routesMap.ADMIN_SIGNUP.path,
      name: "admin signup",
      key: routesMap.ADMIN_SIGNUP.path,
      commonRoute: false,
      private: false,
      belongsToHeader: false,
      element: <SignUp />,
    },
    // {
    //   path: routesMap.VERIFICATION.path,
    //   name: t("text.verification.title"),
    //   key: routesMap.VERIFICATION.path,
    //   commonRoute: false,
    //   private: false,
    //   belongsToHeader: false,
    //   element: <Verification />,
    // },
    // {
    //   path: routesMap.SIGNUP_AS_PROVIDER.path,
    //   name: t("text.signUpAsProvider.title"),
    //   key: routesMap.SIGNUP_AS_PROVIDER.path,
    //   commonRoute: false,
    //   private: false,
    //   belongsToHeader: false,
    //   element: <SignUpAsProvider />,
    // },
  ];
}
