import { notification } from "antd";

const modalNotification = ({ type, message, description, duration }) => {
  notification[type]({
    description,
    message,
    duration: duration || 5,
    style: {
      textTransform: "capitalize",
    },
  });
};

export default modalNotification;
