import { CloudUploadOutlined } from "@ant-design/icons";
import { ResumeForm } from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: routesMap.Resume_Form.path,
      name: "Upload Document",
      key: routesMap.Resume_Form.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: true,
      icon: <CloudUploadOutlined className="text-white" />,
      element: <ResumeForm />,
    },
  ];
}
