import { UserProfile } from "../../../pages";
import adminRouteMap from "../../../routeControl/adminRouteMap";

export default function route() {
  return [
    {
      path: adminRouteMap.PROFILE.path,
      name: "Profile",
      key: adminRouteMap.PROFILE.path,
      commonRoute: false,
      private: true,
      belongsToSidebar: false,
      element: <UserProfile />,
    },
  ];
}
